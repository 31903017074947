export default async () => {
    /**
     * Modules
     */
    let careComponents = [];
    let csComponents = [];
    // Darmin Care
    if (process.env.VUE_APP_DARMIN_CARE) {
        // eslint-disable-next-line nonblock-statement-body-position
        careComponents = (await import('./modules/Care/CareWindows')).default;
    }

    if (process.env.VUE_APP_DARMIN_CS) {
        // eslint-disable-next-line nonblock-statement-body-position
        csComponents = (await import('./modules/CustomerService/CsWindows')).default;
    }

    return {
        components: [
            {
                title: 'Home',
                load: () => import('@/components/home/Home.vue'),
            },
            {
                title: 'Test',
                load: () => import('@/components/test/Test.vue'),
            },
            {
                title: 'MenuEditor',
                load: () => import('@/modules/Darmin/MenuEditor/MenuEditor.vue'),
            },
            {
                title: 'MenuItemForm',
                load: () => import('@/modules/Darmin/MenuEditor/MenuItemForm.vue'),
            },
            {
                title: 'List',
                load: () => import('@/components/list/List.vue'),
            },
            {
                title: 'UserProfileForm',
                load: () => import('@/modules/Darmin/UserProfile/UserProfileForm.vue'),
            },
            {
                title: 'PermissionCategoryForm',
                load: () =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    import('@/modules/Darmin/PermissionCategory/PermissionCategoryForm.vue'),
            },
            {
                title: 'PermissionForm',
                load: () => import('@/modules/Darmin/Permission/PermissionForm.vue'),
            },
            {
                title: 'UserForm',
                load: () => import('@/modules/Darmin/Users/UserForm.vue'),
            },
            {
                title: 'ParametersForm',
                load: () => import('@/modules/Darmin/Parameters/ParametersForm.vue'),
            },
            // DarminCare
            ...careComponents,
            // Customer Service
            ...csComponents,
        ],

        getComponent(title) {
            return this.components.find((obj) => obj.title === title);
        },

        getSelect() {
            return this.components.map((c) => ({ id: c.title, label: c.title }));
        },
    };
};
